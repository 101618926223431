import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { connect } from "react-redux";
import { postSeries, putSeries } from "../../../../Actions/SerialsActions.js";
import { ModalContext } from "../../../modal/ModalContext/ModalContext.tsx";
import { onInputTextAreaChange } from "../sctipts";
import { postUploadImages } from "../../../../Actions/videos-actions/VideosActions.js";
import { useUserAccessContext } from "../../context/UserAccessContext.js";
import { checkUserWritePerm } from "../../scripts/utils.js";

const initialSeries = {
	title: null,
	season_id: null,
	order: null,
};

const initialVideos = {
	title: "",
	detailed_description: "",
	short_description: "",
	URL_SD_PREVIEW: "",
	URL_SD_SOURCE: "",
	URL_HD_SOURCE: "",
	URL_FULL_HD_SOURCE: "",
};

const AddSeriesModal = ({
	postSeries,
	loader,
	elem,
	seasonId,
	putSeries,
	uploadImage,
	postUploadImages,
	filmState,
	seasonIndex,
	serialIndex,
	userAccessState,
}) => {
	const { handleModal } = useContext(ModalContext);
	const [newSeriesState, setNewSeriesState] = useState(initialSeries);
	const [imageErrorState, setImageErrorState] = useState(null);
	const [prewDragState, setPrewDragState] = useState(() => false); // состояние drag для превьюшки
	const [newVideoState, setNewVideoState] = useState(initialVideos);
	const [uploadFlagState, setUploadFlagState] = useState(() => false); // т.к. общее состояние аплоадов, делаю флажок: к хранилищу доступ только при поднятом флаге
	// т.е. пока false он даже не смотрит на аплоад
	const addSeries = () => {
		const videoTitle = `${filmState.title}. Сезон ${filmState.seasons[seasonIndex].title} эпизод ${newSeriesState.title}`;
		postSeries(
			{
				...newSeriesState,
				season_id: seasonId,
				order: filmState.seasons[seasonIndex].episodes.length + 1,
			},
			newVideoState,
			filmState?.movie?.id,
			videoTitle
		);
	};

	const changeSeries = () => {
		const videoTitle = `${filmState.title}. Сезон ${
			filmState.seasons[seasonIndex - 1].title
		} эпизод ${newSeriesState.title}`;
		putSeries(newVideoState, newSeriesState, videoTitle);
	};
	useEffect(() => {
		if (elem) {
			setNewVideoState(elem?.video);
			setNewSeriesState({
				...newSeriesState,
				id: elem?.id ? elem.id : null,
				title: elem.title,
				season_id: elem.season_id,
				order: elem.order,
			});
		}
	}, [elem]);

	const dragStartHandler = (e) => {
		e.preventDefault();
		setPrewDragState(true);
	};

	const dragLeaveHandler = (e) => {
		e.preventDefault();
		setPrewDragState(false);
	};

	const onDropHandler = (e) => {
		e.preventDefault();
		let files = [...e.dataTransfer.files];
		let reader = new FileReader();
		let file = files[0];
		const validFormats = ["image/png", "image/jpeg"];
		const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

		if (!validFormats.includes(file.type)) {
			setImageErrorState("Недопустимый формат изображения");
			setNewVideoState({ ...newVideoState, URL_SD_PREVIEW: undefined });
			return;
		}

		if (file.size > maxSize) {
			setImageErrorState("Размер файла слишком большой");
			setNewVideoState({ ...newVideoState, URL_SD_PREVIEW: undefined });
			return;
		}
		setImageErrorState(null);
		reader.onloadend = () => {
			postUploadImages(file, "preview", 1920, 1080, 1);
			setUploadFlagState(true);
		};
		reader.readAsDataURL(files[0]);
		setPrewDragState(false);
	};

	useEffect(() => {
		const tx = document.getElementsByTagName("textarea");
		for (let i = 0; i < tx.length; i++) {
			tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px;overflow-y:hidden;");
			tx[i].addEventListener("ondeydown", OnInput, false);
		}

		function OnInput() {
			this.style.height = "inherit";
			this.style.height = this.scrollHeight + "px";
		}
		return () => {
			for (let i = 0; i < tx.length; i++) {
				tx[i].setAttribute("style", "height:" + tx[i].scrollHeight + "px;");
				tx[i].removeEventListener("", OnInput, false);
			}
		};
	}, [newSeriesState]);

	const onPrewPhotoChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			let file = event.target.files[0];
			const validFormats = ["image/png", "image/jpeg"];
			const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

			if (!validFormats.includes(file.type)) {
				setImageErrorState("Недопустимый формат изображения");
				setNewVideoState({ ...newVideoState, URL_SD_PREVIEW: undefined });
				return;
			}

			if (file.size > maxSize) {
				setImageErrorState("Размер файла слишком большой");
				setNewVideoState({ ...newVideoState, URL_SD_PREVIEW: undefined });
				return;
			}
			setImageErrorState(null);
			reader.onloadend = () => {
				postUploadImages(file, "preview", 1920, 1080, 1);
				setUploadFlagState(true);
			};
			reader.readAsDataURL(file);
		}
	};

	useEffect(() => {
		if (uploadImage.filename.length > 0 && uploadFlagState) {
			setNewVideoState({ ...newVideoState, URL_SD_PREVIEW: uploadImage.filename });
			setUploadFlagState(false);
		}
	}, [uploadImage]);

	return (
		<>
			<div className="modal-field create-episode-modal active">
				<div className="modal-field-title">
					{!!newSeriesState.id && newSeriesState.id !== null && seasonIndex
						? `${filmState.title}. Сезон ${seasonIndex}. Эпизод ${serialIndex + 1}`
						: `Новый эпизод`}
				</div>
				<div className="modal-field-close" onClick={() => handleModal()}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M4.70701 3.29304L3.99991 2.58594L2.58569 4.00015L3.2928 4.70726L10.5857 12.0002L3.2928 19.293L2.58569 20.0002L3.99991 21.4144L4.70701 20.7073L11.9999 13.4144L19.2928 20.7073L19.9999 21.4144L21.4141 20.0002L20.707 19.293L13.4141 12.0002L20.707 4.70726L21.4141 4.00015L19.9999 2.58594L19.2928 3.29304L11.9999 10.5859L4.70701 3.29304Z"
							fill="#212121"></path>
					</svg>
				</div>
				<div className="flex-block">
					<div className="form-field">
						<div className="form-field-label">Название эпизода</div>
						<div className="input-field">
							<input
								disabled={!checkUserWritePerm(userAccessState, "videos")}
								value={newSeriesState.title === null ? "" : newSeriesState.title}
								onChange={(e) =>
									onInputTextAreaChange(e, "title", newSeriesState, setNewSeriesState)
								}
								placeholder="Ввведите название эпизода"
							/>
						</div>
					</div>
				</div>
				<div
					className="form-container-block-subtitle"
					style={{ marginBottom: "24px", marginTop: "32px" }}>
					Ссылки на видеопоток
				</div>
				<div className="flex-block">
					<div className="form-field">
						<div className="form-field-label">SD</div>
						<div className="input-field">
							<input
								disabled={!checkUserWritePerm(userAccessState, "videos")}
								value={newVideoState.URL_SD_SOURCE === null ? "" : newVideoState.URL_SD_SOURCE}
								onChange={(e) =>
									onInputTextAreaChange(e, "URL_SD_SOURCE", newVideoState, setNewVideoState)
								}
							/>
						</div>
					</div>
				</div>
				<div className="flex-block">
					<div className="form-field">
						<div className="form-field-label">HD</div>
						<div className="input-field">
							<input
								disabled={!checkUserWritePerm(userAccessState, "videos")}
								value={newVideoState.URL_HD_SOURCE === null ? "" : newVideoState.URL_HD_SOURCE}
								onChange={(e) =>
									onInputTextAreaChange(e, "URL_HD_SOURCE", newVideoState, setNewVideoState)
								}
							/>
						</div>
					</div>
				</div>
				<div className="flex-block">
					<div className="form-field">
						<div className="form-field-label">Full HD</div>
						<div className="input-field">
							<input
								disabled={!checkUserWritePerm(userAccessState, "videos")}
								value={
									newVideoState.URL_FULL_HD_SOURCE === null ? "" : newVideoState.URL_FULL_HD_SOURCE
								}
								onChange={(e) =>
									onInputTextAreaChange(e, "URL_FULL_HD_SOURCE", newVideoState, setNewVideoState)
								}
							/>
						</div>
					</div>
				</div>
				<div className="form-container-block-subtitle" style={{ marginBottom: "16px" }}>
					Превью
				</div>
				<div
					className="flex-block form-container-block-preview"
					onDragStart={(e) => checkUserWritePerm(userAccessState, "videos") && dragStartHandler(e)}
					onDragLeave={(e) => checkUserWritePerm(userAccessState, "videos") && dragLeaveHandler(e)}
					onDragOver={(e) => checkUserWritePerm(userAccessState, "videos") && dragStartHandler(e)}
					onDrop={(e) => checkUserWritePerm(userAccessState, "videos") && onDropHandler(e)}>
					<div className="form-container-block-preview-data">
						<img src="images/icons/uploadimage.svg" alt="upload" />
						{!prewDragState || imageErrorState ? (
							<>
								<span>
									Перетащите изображение в это окно
									<br />
									или нажмите, чтобы выбрать
								</span>{" "}
								Рекомендуемый размер изображений
								<br />
								не менее 1920x1080 px
								<br />
								<br />
								Формат изображений: jpg, png
								<br />
								Размер файла не должен превышать 5 мб
								{imageErrorState && (
									<span style={{ marginTop: "10px", color: "#ED0A34" }}>{imageErrorState}</span>
								)}
							</>
						) : (
							<span>Отпустите изображение</span>
						)}
					</div>
					<div
						className="form-container-block-preview-image"
						style={{
							backgroundImage: `url('https://atips.io/${newVideoState.URL_SD_PREVIEW}')`,
							backgroundSize: "auto 90%",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "50% 50%",
						}}></div>
					<input
						disabled={!checkUserWritePerm(userAccessState, "videos")}
						type="file"
						name="URL_SD_PREVIEW"
						accept="image/jpg, image/jpeg, image/png, image/webp"
						onChange={onPrewPhotoChange}
					/>
				</div>
				<div className="flex-block flex-start">
					<div className="form-field">
						<div className="form-field-label">Краткое описание</div>
						<div className="input-field">
							<textarea
								disabled={!checkUserWritePerm(userAccessState, "videos")}
								name=""
								value={
									newVideoState.short_description === null ? "" : newVideoState.short_description
								}
								onChange={(e) =>
									onInputTextAreaChange(e, "short_description", newVideoState, setNewVideoState)
								}
								placeholder="Введите краткое описание эпизода"></textarea>
						</div>
					</div>
				</div>
				<div className="flex-block flex-start">
					<div className="form-field">
						<div className="form-field-label">Подробное описание</div>
						<div className="input-field">
							<textarea
								disabled={!checkUserWritePerm(userAccessState, "videos")}
								value={
									newVideoState.detailed_description === null
										? ""
										: newVideoState.detailed_description
								}
								onChange={(e) =>
									onInputTextAreaChange(e, "detailed_description", newVideoState, setNewVideoState)
								}
								name=""
								placeholder="Введите подробное описание эпизода"></textarea>
						</div>
					</div>
				</div>
				{checkUserWritePerm(userAccessState, "videos") ? (
					<div className="modal-buttons">
						<div className="button action-button border-button" onClick={() => handleModal()}>
							<span>Отменить</span>
						</div>
						{!!newSeriesState.id ? (
							<div
								className={`button action-button  ${loader.loading !== 0 ? "preloader" : ""}`}
								onClick={() => changeSeries()}>
								<span>Изменить эпизод</span>
							</div>
						) : (
							<div
								className={`button action-button  ${loader.loading !== 0 ? "preloader" : ""}`}
								onClick={() => addSeries()}>
								<span>Создать эпизод</span>
							</div>
						)}
					</div>
				) : (
					<div className="modal-buttons">
						<div></div>
						<div className="button action-button border-button" onClick={() => handleModal()}>
							<span>Вернуться</span>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loader: state.loadingStatus,
		uploadImage: state.uploadImage,
	};
};

const mapDispatchToProps = {
	postSeries,
	postUploadImages,
	putSeries,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSeriesModal);

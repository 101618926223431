export const baseUrl = process.env.REACT_BASE_URL || "https://api.dev.testatips.ru/";
// export const baseUrl = "https://api.atips.io";
export const cinemaUrl =
	process.env.ENVIRONMENT === "production"
		? "https://api.atips.media"
		: "https://dev.api.atips.media";
export const marketUrl =
	process.env.ENVIRONMENT === "production"
		? "https://api.market.atips.io"
		: "https://dev.api.market.atips.io";

export const bloggerUrl =
	process.env.ENVIRONMENT === "production"
		? "https://api.creator.atips.io"
		: "https://dev.api.creator.atips.io";

export const graphqlDomen =
	process.env.ENVIRONMENT === "production"
		? "https://stat.atips.io/graphql"
		: "https://devstat.testatips.ru/graphql";

// other
export const updateCategoryUrl =
	process.env.ENVIRONMENT === "production"
		? "https://atips.io/pregenerate/index.php"
		: "https://atips.io/pregenerate/indexdev.php";

export const CONTRUCTOR_URL =
	process.env.ENVIRONMENT === "production"
		? "https://atips.io/constructor"
		: "https://bconstructor.testatips.ru/dev";

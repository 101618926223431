import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ModalContext } from "../../../modal/ModalContext/ModalContext";
import { deleteUser, getCurrentUser, postUser, putUser } from "../../../../Actions/UsersActions.js";
import "./styles/EditUsers.scss";
import { postUploadImages } from "../../../../Actions/videos-actions/VideosActions.js";
import UniversalDeleteModal from "../../../universal/UniversalDeleteModal.jsx";
import { getAllActiveOwner } from "../../../../Actions/OwnerActions.js";
import { useUserAccessContext } from "../../context/UserAccessContext";
import { checkUserWritePerm } from "../../scripts/utils";
import { useNavigate } from "react-router-dom";

const EditUserSidebar = ({
	postUser,
	putUser,
	uploadImage,
	postUploadImages,
	deleteUser,
	setErrorState,
	mainUserState,
	setMainUserState,
}) => {
	const { handleModal } = useContext(ModalContext);
	const [uploadFlagState, setUploadFlagState] = useState(() => false); // т.к. общее состояние аплоадов, делаю флажок: к хранилищу доступ только при поднятом флаге
	const { userAccessState } = useUserAccessContext();
	const [imageErrorState, setImageErrorState] = useState(null);
	const navigate = useNavigate();
	const handlePostPutUserClick = (type) => {
		if (type === "POST") {
			if (
				mainUserState.first_name.length === 0 ||
				mainUserState.second_name.length === 0 ||
				mainUserState.email.length === 0 ||
				mainUserState.password.length === 0
			) {
				setErrorState(true);
				return;
			}
			if (!mainUserState.owner) postUser({ ...mainUserState, owner: 1 });
			else {
				postUser(mainUserState);
			}
		} else if (type === "PUT") {
			if (
				mainUserState.first_name.length === 0 ||
				mainUserState.second_name.length === 0 ||
				mainUserState.email.length === 0
			) {
				setErrorState(true);
				return;
			}
			if (!mainUserState.owner) putUser({ ...mainUserState, owner: 1 }, mainUserState.id);
			else {
				putUser(mainUserState, mainUserState.id);
			}
		} else if (type === "DELETE") {
			handleModal(
				<UniversalDeleteModal
					title={`пользователя`}
					deleteFunc={deleteUser}
					id={mainUserState.id}
					goBack={true}
				/>
			);
		}
	};
	const onPrewPhotoChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			let reader = new FileReader();
			let file = event.target.files[0];
			const validFormats = ["image/png", "image/jpeg"];
			const maxSize = 5 * 1024 * 1024; // 5 MB in bytes

			if (!validFormats.includes(file.type)) {
				setImageErrorState("Недопустимый формат изображения");
				setMainUserState({ ...mainUserState, avatar_url: undefined });
				return;
			}

			if (file.size > maxSize) {
				setImageErrorState("Размер файла слишком большой");
				setMainUserState({ ...mainUserState, avatar_url: undefined });
				return;
			}
			setImageErrorState(null);

			reader.onloadend = () => {
				postUploadImages(file, "preview", 238, 238, 1);
				setUploadFlagState(true);
			};
			reader.readAsDataURL(file);
		}
	};
	useEffect(() => {
		if (!!uploadImage.filename && uploadImage.filename.length > 0 && uploadFlagState) {
			setMainUserState({ ...mainUserState, avatar_url: uploadImage.filename });
			setUploadFlagState(false);
		}
	}, [uploadImage]);

	console.log(imageErrorState);
	return (
		<>
			<div className="form-container-upload">
				{mainUserState.avatar_url?.length > 0 ? (
					<div
						className="form-container-block-preview-image"
						style={{
							backgroundImage: `url('https://atips.io/${mainUserState.avatar_url}')`,
							backgroundSize: "auto 100%",
							backgroundRepeat: "no-repeat",
							backgroundPosition: "50% 50%",
						}}></div>
				) : null}

				<svg
					width="24"
					height="24"
					viewBox="0 0 24 24"
					fill="none"
					xmlns="http://www.w3.org/2000/svg">
					<g clipPath="url(#clip0_2602_2011)">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M8.94596 4.45392C9.13054 4.17076 9.44567 4 9.78369 4H14.2163C14.5543 4 14.8695 4.17076 15.054 4.45392L16.0705 6.01324C16.6242 6.86273 17.5696 7.375 18.5837 7.375H21C21.5523 7.375 22 7.82272 22 8.375V19C22 19.5523 21.5523 20 21 20H12H3C2.44772 20 2 19.5523 2 19L2 8.375C2 7.82272 2.44772 7.375 3 7.375H5.41631C6.43035 7.375 7.37577 6.86273 7.92951 6.01324L8.94596 4.45392ZM9.78369 2C8.76965 2 7.82423 2.51227 7.27049 3.36176L6.25405 4.92108C6.06946 5.20424 5.75433 5.375 5.41631 5.375H3C1.34315 5.375 0 6.71815 0 8.375L0 19C0 20.6569 1.34315 22 3 22H12H21C22.6569 22 24 20.6569 24 19V8.375C24 6.71815 22.6569 5.375 21 5.375H18.5837C18.2457 5.375 17.9305 5.20424 17.746 4.92108L16.7295 3.36176C16.1758 2.51227 15.2304 2 14.2163 2L9.78369 2ZM9 13C9 11.3431 10.3431 10 12 10C13.6569 10 15 11.3431 15 13C15 14.6569 13.6569 16 12 16C10.3431 16 9 14.6569 9 13ZM12 8C9.23858 8 7 10.2386 7 13C7 15.7614 9.23858 18 12 18C14.7614 18 17 15.7614 17 13C17 10.2386 14.7614 8 12 8Z"
							fill="#94A0B1"></path>
					</g>
					<defs>
						<clipPath id="clip0_2602_2011">
							<rect width="24" height="24" fill="white"></rect>
						</clipPath>
					</defs>
				</svg>
				<input
					type="file"
					first_name="preview"
					accept="image/jpg, image/jpeg, image/png, image/webp"
					onChange={onPrewPhotoChange}
				/>
			</div>
			{checkUserWritePerm(userAccessState, "users") ? (
				mainUserState.avatar_url?.length > 0 || imageErrorState ? (
					<>
						<div className="add-photo-button">
							<svg
								width="24"
								height="24"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M16.6203 2.24996C16.4327 2.06241 16.1784 1.95704 15.9132 1.95703C15.6479 1.95702 15.3936 2.06238 15.206 2.24992L5.58574 11.8701C5.21067 12.2451 4.99995 12.7538 4.99995 13.2843L4.99995 17.0005C4.99995 17.2657 5.10531 17.5201 5.29284 17.7076C5.48038 17.8951 5.73473 18.0005 5.99995 18.0005H9.71616C10.2466 18.0005 10.7553 17.7898 11.1304 17.4147L20.7503 7.79457C21.1408 7.40406 21.1408 6.77092 20.7503 6.3804L16.6203 2.24996ZM6.99995 13.2843L15.9131 4.37127L18.629 7.08744L9.71616 16.0005H6.99995L6.99995 13.2843ZM2.99994 20.0007H1.99994L1.99994 22.0007H2.99994H20.9999H21.9999V20.0007H20.9999H2.99994Z"
									fill="#94A0B1"></path>
							</svg>
							Изменить
							<input
								type="file"
								first_name="preview"
								accept="image/jpg, image/jpeg, image/png, image/webp"
								onChange={onPrewPhotoChange}
							/>
						</div>
						{imageErrorState && (
							<span style={{ marginTop: "10px", color: "#ED0A34", fontSize: "14px", textAlign: "center" }}>{imageErrorState}</span>
						)}
					</>
				) : (
					<div className="add-photo-button">
						<svg
							width="24"
							height="24"
							viewBox="0 0 24 24"
							fill="none"
							xmlns="http://www.w3.org/2000/svg">
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M13 6V5L11 5V6L11 11H6H5L5 13H6H11L11 18V19H13V18L13 13H18L19 13V11L18 11H13L13 6Z"
								fill="#94A0B1"></path>
						</svg>
						<input
							type="file"
							first_name="preview"
							accept="image/jpg, image/jpeg, image/png, image/webp"
							onChange={onPrewPhotoChange}
						/>
						Добавить фото
					</div>
				)
			) : null}
			<div className="form-container-right-divider"></div>
			{checkUserWritePerm(userAccessState, "users") ? (
				!!mainUserState.id ? (
					<div className="button action-button" onClick={() => handlePostPutUserClick("PUT")}>
						<span>Сохранить</span>
					</div>
				) : (
					<div className="button action-button" onClick={() => handlePostPutUserClick("POST")}>
						<span>Создать пользователя</span>
					</div>
				)
			) : (
				<div className="button action-button" onClick={() => navigate(-1)}>
					<span>Вернуться</span>
				</div>
			)}
			{checkUserWritePerm(userAccessState, "users") && !!mainUserState.id ? (
				<div
					className="remove-video-button action-button"
					onClick={() => handlePostPutUserClick("DELETE")}>
					<svg
						width="24"
						height="24"
						viewBox="0 0 24 24"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M7 5C7 3.34315 8.34315 2 10 2L14 2C15.6569 2 17 3.34315 17 5V6H18L20 6H21V8H20H18.9311L18.1301 19.2137C18.018 20.7837 16.7117 22 15.1378 22H8.86224C7.28832 22 5.982 20.7837 5.86986 19.2137L5.06888 8H4H3V6H4H6H7V5ZM9 6L15 6V5C15 4.44772 14.5523 4 14 4L10 4C9.44772 4 9 4.44772 9 5V6ZM7.07398 8L7.86478 19.0712C7.90216 19.5946 8.3376 20 8.86224 20H15.1378C15.6624 20 16.0978 19.5946 16.1352 19.0712L16.926 8H16L8.0003 8H8H7.9997H7.07398Z"
							fill="#94A0B1"></path>
					</svg>
					Удалить пользователя
				</div>
			) : null}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		loadingStatus: state.loadingStatus,
		currentUser: state.currentUser,
		uploadImage: state.uploadImage,
		ownersList: state.ownersList.active,
	};
};

const mapDispatchToProps = {
	getCurrentUser,
	postUser,
	putUser,
	postUploadImages,
	deleteUser,
	getAllActiveOwner,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserSidebar);
